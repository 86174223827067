<template>
  <c-step
    stepperGrpCd="LICENSE_REQUEST_STEP_CD"
    :param="param"
    :currentStepCd="stepCd"
    v-model="stepCd"
    @currentStep="currentStep"
    @setId="setId"
    @closePopup="closePopup"
  >
  </c-step>
</template>

<script>
export default {
  name: 'license-request-step',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        stepCd: '',
        limLicenseRequestId: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      stepCd: '',
    };
  },
  watch: {
  },
  computed: {
    param() {
      return {
        limLicenseRequestId: this.popupParam.limLicenseRequestId,
      }
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.setStep();
    },
    setId(limLicenseRequestId) {
      this.popupParam.limLicenseRequestId = limLicenseRequestId;
    },
    setStep() {
      if (!this.popupParam.stepCd || this.popupParam.stepCd === 'RSC0000001' || this.popupParam.stepCd === 'RSC0999999') {
        this.stepCd = 'LRSC000001'
      } else {
        this.stepCd = 'LRSC000002'
      }
    },
    currentStep(step) {
      this.$set(this.popupParam, 'stepCd', step)
      this.setStep();
    },
    closePopup() {
      this.$emit('closePopup')
    },
  }
};
</script>
